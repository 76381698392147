
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSearchbar, IonList, IonThumbnail, IonLabel, IonItem } from '@ionic/vue';
//import recipes from "../../dummy/recipes.json";
import { convert } from "html-to-text";
import { Haptics, ImpactStyle } from '@capacitor/haptics';


export default  {
    name: 'Recipes',
    components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonSearchbar, IonList, IonThumbnail, IonLabel, IonItem },
    data() {
        return {
            searchTerm: "",
        }
    },
    computed: {
        recipeList(): any {
            const result = [].filter((r) => {
                return this.searchTerm ? Boolean(r.name.find((v) => v.lang === "de-DE").value.toLowerCase().includes(this.searchTerm.toLowerCase())) : true
            }).map((r) => {
                return {
                    ...r,
                    name: r.name?.find((v) => v.lang === "de-DE")?.value,
                    tradeName: r.tradeName?.find((v) => v.lang === "de-DE")?.value,
                    shortDescription: convert(r.shortDescription?.find((v) => v.lang === "de-DE")?.value),
                }
            });

            if (result.length === 0) {
                Haptics.impact({ style: ImpactStyle.Medium });
            }

            return result;

        }
    },
}
